import { ChevronRightIcon } from '@heroicons/react/outline'
import HomeCTACard from 'components/HomeCTACard'
import KDTKlassCard from 'components/KDTKlassCard'
import KDTPreNoticeBanner from 'components/KDTPreNoticeBanner'
import KDTSchedule from 'components/KDTSchedule'
import KdcSchoolSkeleton from 'components/Skeleton/KdcSchoolSkeleton'
import KdtSchoolSkeleton from 'components/Skeleton/KdtSchoolSkeleton'
import MainBannerSkeleton from 'components/Skeleton/MainBannerSkeleton'
import dayjs from 'dayjs'
import ApplyPersonalCard from 'features/apply/components/ApplyPersonalCard'
import { useCurrentMyKDTApplication } from 'features/apply/hooks/useCurrentMyKDTApplication'
import { useMyKDTApplyList } from 'features/apply/queries/useMyKDTApplyList'
import { IKlass } from 'features/klass/types'
import TrackCard from 'features/onboarding/components/TrackCard'
import Banner from 'features/showroom/components/Banner'
import { useBannerListQuery } from 'features/showroom/queries/useBannerListQuery'
import { useShowroomQuery } from 'features/showroom/queries/useShowroomQuery'
import { sortedList } from 'features/showroom/utils'
import localforage from 'localforage'
import { chain, isEqual, map } from 'lodash'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import { MyKDTApplyStatus } from 'stores/apply'

import homeCTACardList from '../mocks/homeCTACardList.json'
import { useAuth } from '../shared/contexts/Auth'
import WithHeader from '../shared/layout/WithHeader'
import styles from './Home.module.scss'

const Home = () => {
  const [isDeferred, setIsDeferred] = useState(false)
  const { isSigned } = useAuth()
  const { data: showroomKDTList, isLoading: isLoadingKDT } = useShowroomQuery('home', 'kdt')
  const { data: showroomKDCList, isLoading: isLoadingKDC } = useShowroomQuery('home', 'kdc')
  const { data: bannerList, isLoading: isLoadingBanner } = useBannerListQuery()
  const { data: myKdtApplyList } = useMyKDTApplyList()
  const {
    currentMyKDTApplication,
    applyStatus,
    currentApplyEndedAt,
    currentApplyStartedAt,
    currentApplyInfo,
    currentAction,
  } = useCurrentMyKDTApplication(myKdtApplyList ?? [])

  const isSimplifyKDT = useMemo(() => currentMyKDTApplication?.flags?.includes('new-kdt'), [
    currentMyKDTApplication,
  ])

  const showroomListUrl = ['/school', '/onboarding', '/hrd-card']

  const homCTACards = map(homeCTACardList, (element, index) => ({
    ...element,
    url: showroomListUrl[index],
  }))

  const toggleChannelTalk = async () => {
    try {
      const isShowMessenger = await localforage.getItem('isShowMessenger')
      if (isShowMessenger === 'true') {
        window.ChannelIO('showMessenger')
      } else {
        window.ChannelIO('hideMessenger')
      }
    } catch (e) {
      console.error(e)
    }
  }

  const clearIsShow = async () => {
    await localforage.removeItem('isShowMessenger')
  }
  useEffect(() => {
    void toggleChannelTalk()

    return () => {
      void clearIsShow()
    }
  }, [])

  const getKdtPlusApplyList = useMemo(() => {
    let kdtPlusList: IKlass[] = []
    const result: IKlass[] = []
    if (showroomKDTList) {
      kdtPlusList = showroomKDTList[0].courses.filter(
        klass => klass.id.includes('frontendplus') || klass.id.includes('backendplus'),
      )
    }
    kdtPlusList = sortedList(kdtPlusList, 'apply')
    kdtPlusList.forEach(v => {
      if (v.id.includes('frontend')) {
        result.unshift(v)
      }
      if (v.id.includes('backend')) {
        result.push(v)
      }
    })
    return result
  }, [showroomKDTList])

  const checkPrevStepApplicationPassed = (
    data: MyKDTApplyStatus[],
    type: string,
    applyStatus: string,
    assignmentStartedAt: string | null,
    participationStartedAt: string | null,
    currentApplyInfo: { status: string; isFinished: boolean },
  ) => {
    const applyPersonalStatus = chain(data).filter(['applyType', type]).head().get('status').value()
    const curStatus = chain(currentApplyInfo).get('status').value()
    const isPrevStepPassed = chain(data)
      .filter(['applyType', type])
      .head()
      .get('status')
      .isEqual('passed')
      .value()

    if (isSimplifyKDT) {
      if (applyStatus === 'assignment' || applyStatus === 'assignmentReview') {
        switch (curStatus) {
          case 'pending':
            return dayjs().isAfter(dayjs(participationStartedAt)) ? false : true
        }
        return true
      }
      if (applyStatus === 'participation' || applyStatus === 'participationReview') {
        switch (applyPersonalStatus) {
          case 'passed':
            switch (curStatus) {
              case 'pending':
                return true
              case 'passed':
                return true
              case 'inited':
                return true
            }
            break
          case 'spare':
            return true
        }
      }
      return false
    } else {
      switch (type) {
        case 'apply':
          switch (applyPersonalStatus) {
            case 'pending':
              return dayjs().isAfter(dayjs(assignmentStartedAt)) ? false : true
            case 'passed':
              return true
            case 'failed':
              if (applyStatus.includes('Review')) return true
          }
          break
        case 'assignment':
          applyStatus.includes('Review') ? true : isPrevStepPassed
          break
      }
      if (
        (applyStatus === 'participation' || applyStatus === 'participationReview') &&
        isPrevStepPassed
      ) {
        const isParticipationInit = data.length === 2
        const isParticipationReviewing = chain(data)
          .filter(['applyType', 'participation'])
          .head()
          .get('status')
          .isEqual('pending')
          .value()
        return isParticipationInit ? true : isParticipationReviewing
      }
      return false
    }
  }

  const kdtShowroomList = useMemo(() => {
    if (showroomKDTList) {
      return [
        ...sortedList(
          showroomKDTList[0].courses.filter(
            klass => !klass.id.includes('frontendplus') && !klass.id.includes('backendplus'),
          ),
          'apply',
        ),
        ...getKdtPlusApplyList,
        ...sortedList(showroomKDTList[0].courses, 'early'),
        ...sortedList(showroomKDTList[0].courses, 'end'),
      ]
    }
  }, [getKdtPlusApplyList, showroomKDTList])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsDeferred(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <WithHeader>
      <main className={`relative ${styles.MinWidth}`}>
        {/* Hero card */}
        {isLoadingBanner && isDeferred ? (
          <MainBannerSkeleton />
        ) : (
          bannerList && <Banner bannerList={bannerList} />
        )}
        {/* 개인화 */}
        {isSigned &&
          currentMyKDTApplication &&
          applyStatus &&
          currentApplyInfo &&
          checkPrevStepApplicationPassed(
            currentMyKDTApplication.applies,
            isSimplifyKDT ? prevSimplifyStatus[applyStatus] : prevStatus[applyStatus],
            applyStatus,
            currentMyKDTApplication.kdtAttr.assignmentStartedAt,
            currentMyKDTApplication.kdtAttr.participationStartedAt,
            currentApplyInfo,
          ) &&
          currentAction && (
            <section className="mx-4 mt-12 max-w-7xl px-0 lg:mx-auto lg:mt-20 lg:px-6">
              <h2 className={`text-xl font-semibold lg:text-2xl ${styles.fontBreakWord}`}>
                작성 중인 지원서가 있어요!
              </h2>
              <ApplyPersonalCard
                title={currentMyKDTApplication.title}
                image={currentMyKDTApplication.imageM ?? ''}
                imageM={currentMyKDTApplication.ogImage ?? ''}
                endedAt={currentApplyEndedAt}
                startedAt={currentApplyStartedAt}
                participationStartedAt={currentMyKDTApplication.kdtAttr.participationStartedAt}
                applyInfo={currentMyKDTApplication.applies}
                applyAction={currentAction}
                isIngKlass={isEqual(applyStatus, 'ing')}
                courseId={currentMyKDTApplication.id}
                applyStatus={applyStatus}
                isSimplifyKDT={isSimplifyKDT as boolean}
              />
            </section>
          )}
        <section className="mt-10 md:mt-20">
          <div className="mx-4 max-w-7xl px-0 md:mx-auto md:px-6">
            <div className="flex items-center justify-between lg:items-end">
              <h2 className={`${styles.sectionTitle}`}>국비지원 부트캠프</h2>
              <Link href={'/school'} as="/school">
                <a className="flex items-center">
                  <span className="mr-1 text-xs font-semibold md:text-sm">자세히 보기</span>
                  <ChevronRightIcon width={16} />
                </a>
              </Link>
            </div>
          </div>
          <div className="mt-8 max-w-7xl px-4 lg:mx-auto lg:mt-10 lg:px-6">
            {isLoadingKDT && isDeferred ? (
              <ul
                id="home"
                className="grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-4 lg:gap-x-6">
                {[1, 2, 3, 4].map(el => (
                  <KdtSchoolSkeleton key={`${el}_skeleton`} />
                ))}
              </ul>
            ) : (
              <ul
                id="home"
                className="grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-4 lg:gap-x-6">
                {kdtShowroomList?.map(course => (
                  <KDTKlassCard course={course} key={course.id} />
                ))}
              </ul>
            )}
          </div>
        </section>
        {/* KDT 연간 일정 */}
        <section className="mt-10 max-w-7xl md:mx-auto md:mt-20 lg:px-6">
          <div className="px-4 lg:px-0">
            <h3 className={`hidden lg:block ${styles.sectionTitle}`}>
              국비지원 부트캠프 오픈 일정
            </h3>
          </div>
          {showroomKDTList && <KDTSchedule showroom={[...showroomKDTList[0].courses]} />}
        </section>
        {/* KDT 사전알림 */}
        <section className="mt-10 h-full w-full overflow-hidden md:mt-20">
          <KDTPreNoticeBanner
            imgSrc={{
              mobile:
                'https://d35ai18pny966l.cloudfront.net/course/pre-notice/home_banner_360_prenotice.webp',
              tablet:
                'https://d35ai18pny966l.cloudfront.net/course/pre-notice/home_banner_768_prenotice.webp',
              laptop:
                'https://d35ai18pny966l.cloudfront.net/course/pre-notice/home_banner_1280_prenotice.webp',
              desktop:
                'https://d35ai18pny966l.cloudfront.net/course/pre-notice/home_banner_1920_prenotice.webp',
            }}
          />
        </section>
        {/* KDC */}
        <section className="mt-10 lg:mt-20">
          <div className="mx-4 max-w-7xl space-y-2 px-0 md:mx-auto md:px-6 lg:space-y-10">
            <div className="flex items-center justify-between lg:items-end">
              <h2 className={`${styles.sectionTitle}`}>6주완성</h2>
              <Link href={'/onboarding'} as="/onboarding">
                <a className="flex items-center">
                  <span className="mr-1 text-xs font-semibold md:text-sm">자세히 보기</span>
                  <ChevronRightIcon width={16} />
                </a>
              </Link>
            </div>
            {(isLoadingKDC && isDeferred) || showroomKDCList === undefined ? (
              <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-3 lg:gap-x-6 lg:gap-y-10">
                {[1, 2, 3].map(el => (
                  <KdcSchoolSkeleton key={`${el}_kdcSkeleton`} />
                ))}
              </div>
            ) : (
              <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-3 lg:gap-x-6 lg:gap-y-10">
                {showroomKDCList[0].courses.map((course, idx) => (
                  <TrackCard key={`${course.id}_track_${idx}`} data={course} />
                ))}
              </div>
            )}
          </div>
        </section>
        {/* 모든 교육 경험 */}
        <section className="mb-20 mt-10 max-w-7xl md:mx-auto md:mt-20 md:px-6 lg:mb-40">
          <div className="mx-4 md:mx-0">
            <h3 className={`${styles.sectionTitle}`}>멋사가 두드리는 새로운 교육</h3>
          </div>
          <div className={'mx-auto mt-8 flex w-full flex-col px-4 md:px-0 lg:mt-10'}>
            <div className="flex flex-col flex-wrap justify-between gap-y-8 md:flex-row">
              {homCTACards.map(({ title, subTitle, url, bgImg }) => (
                <HomeCTACard
                  title={title}
                  subTitle={subTitle}
                  url={url}
                  key={`${title}_${url}`}
                  bgImg={bgImg}
                />
              ))}
            </div>
          </div>
        </section>
      </main>
    </WithHeader>
  )
}

interface IPrevStatus {
  [status: string]: string
}

const prevStatus: IPrevStatus = {
  apply: 'apply',
  applyReview: 'apply',
  assignment: 'apply',
  assignmentReview: 'apply',
  participation: 'assignment',
  participationReview: 'assignment',
  ing: 'participation',
}

const prevSimplifyStatus: IPrevStatus = {
  assignment: 'assignment',
  assignmentReview: 'assignment',
  participation: 'assignment',
  participationReview: 'assignment',
  ing: 'participation',
}

export default Home
